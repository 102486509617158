import React from 'react'
import styled from 'styled-components'
import { rgba, rem } from 'polished'
import { Controller, Scene } from 'react-scrollmagic'
import { Tween, SplitLetters } from 'react-gsap'

import { AltFont, PDBold } from '../../../utils/variables'

import { ParallaxBg, LaptopImage } from './images'

class WebDevBackground extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isTablet: typeof window !== 'undefined' && window.innerWidth < 1199,
    };
  }
  render() {
    const { isTablet } = this.state

    const parallaxStart = isTablet ? -25 : -15
    const parallaxEnd = isTablet ? 5 : 10

    const lineOffset = isTablet ? 1500 : 2250
    const lineDuration = isTablet ? '120%' : '200%'

    return (
      <>
        {/* Vertical Line */}
        <Controller>
          <Scene
            duration={lineDuration}
            offset={lineOffset}
            reverse={false}
            triggerElement={null}
            // indicators={true}
          >
            {(progress) => (
              <Tween
                to={{
                  bottom: '0'
                }}
                totalProgress={progress}
                paused
              >
                <VerticalLine />
              </Tween>
            )}
          </Scene>
        </Controller>
        {/* Background Text */}
        <BackgroundText>
          <Controller>
            <Scene
              // pin={false}
              reverse={false}
              duration={1000}
              offset={0}
              // indicators={true}
            >
              <Tween
                wrapper={
                  <div className="textContainer" />
                }
                staggerFrom={{
                  bottom: -2000,
                  rotation: -180,
                  opacity: 0,
                  ease: 'Expo.easeOut',
                }}
                stagger={0.15}
              >
                <SplitLetters>
                  <span className="text">Digital Marketing</span>
                </SplitLetters>
              </Tween>
            </Scene>
          </Controller>
        </BackgroundText>
        <ParallaxBg 
          parallaxStart={parallaxStart} 
          parallaxEnd={parallaxEnd} 
        />
        <LaptopImage />
      </>
    )
  }
}

export default WebDevBackground

const VerticalLine = styled.div`
  width: 0px;
  border-right: 2px dashed ${rgba('#fff', 0.2)};
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%,0);
  z-index: 500;
`

const BackgroundText = styled.div`
  font-family: ${AltFont};
  font-size: ${rem('170px')};
  font-weight: ${PDBold};
  font-style: italic;
  color: ${rgba('#fff', 0.05)};
  transform: translate(-49%,-50%) rotate(90deg);
  top: 50%;
  left: 50%;
  letter-spacing: -12px;
  z-index: 275;
  width: 100%;
  position: relative;
  overflow: hidden;
  text-align: center;
  display: none;
  .textContainer {
    position: relative;
    display: inline-block;
    transform-origin: center;
  }
  .text {
    position: relative;
    display: inline-block;
  }
  @media (min-width: 1200px) {
    position: absolute;
    display: inline-block;
  }
  @media (min-width: 1400px) {
    font-size: ${rem('190px')};
  }
`
import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import { rem, rgba } from 'polished'
import AniLink from 'gatsby-plugin-transition-link/AniLink'

import { AltFont } from '../../../utils/variables'
import { LinkAnimation } from '../../Menu'
import Copyright from '../../Footer/copyright'


export default () => (
  <StaticQuery
    query={graphql`
      query footerQuery {
        site {
          siteMetadata {
            title
          }
        }
        allWordpressWpApiMenusMenusItems(
          filter: {
            wordpress_id: {
              eq: 5
            }
          }
        ) {
          edges {
            node {
              items {
                title
                url
              }
            }
          }
        }
      }
    `}
    render={data =>
        <FooterBottom>
          <HomepageCopyright />
          <FooterMenu>
            {data.allWordpressWpApiMenusMenusItems.edges[0].node.items.map((item, index) =>
              <li key={index}>
                <AniLink
                  to={item.url}
                  cover
                  bg={LinkAnimation}
                  direction="right"
                  duration={2}
                  dangerouslySetInnerHTML={{ __html: item.title }}
                />
                {data.allWordpressWpApiMenusMenusItems.edges[0].node.items.length -1 !== index && <span dangerouslySetInnerHTML={{ __html: "|"}} />}
              </li>
            )}
          </FooterMenu>
        </FooterBottom>        
    }    
  />
)

const FooterBottom = styled.div`  
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  * {
    font-family: ${AltFont};
    font-size: ${rem('16px')};
    letter-spacing: 0;
  }
`

export const HomepageCopyright = styled(Copyright)`  
  margin: ${rem('45px')} 0 0 0;
  flex: 0 0 100%;
  max-width: 100%;
  @media (min-width: 992px) {
    flex: initial;
    max-width: initial;
  }
`

const FooterMenu = styled.ul`
  margin: ${rem('45px')} 0 0 ${rem('10px')};
  display: none; 
  li {
    float: left;
    span {
      margin: 0 ${rem('10px')};
    }
    a {
      border-bottom: 1px dotted ${rgba('#fff',0.2)};
      padding: 0 0 ${rem('7px')};
      transition: all 0.3s ease;
      &:hover, &:active, &:focus {
        color: #fff;
        border-bottom: 1px dotted ${rgba('#fff', 0.6)};
      }
    }
  }
  @media (min-width: 992px) {
    display: inline-block;
  }
`
import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import { rem } from 'polished'

import Form from '../../Form'

import { AltFont, PDBold } from '../../../utils/variables'

export default () => (
  <StaticQuery
   query={graphql`
    query footerFormQuery {
      wordpressPage(wordpress_id: { eq: 313 }) {  
        acf {
          form_group {
            title
            wordpress_fields {
              type
              name
              width
              required
              button_label
              message
              placeholder
              input_type
              maxlength
            }
          }
        }
      }
    }
   `}
   render={data =>
      <>
        <FormTitle dangerouslySetInnerHTML={{ __html: data.wordpressPage.acf.form_group[0].title }} />
        <Form data={data.wordpressPage.acf.form_group} name="homepage" />
      </>
    } 
    
  />
)

const FormTitle = styled.h5`
  font-family: ${AltFont};
  font-size: ${rem('64px')};
  font-weight: ${PDBold};
  font-style: italic;
  letter-spacing: -0.03em;
  margin: 0 0 ${rem('60px')} 0;
  display: none;
  @media (min-width: 1200px) {
    display: inline-block;
  }
`

// const FormContainer = styled.div`
//   display: flex;
//   margin: 0 -10px;
// `

// const FormLeft = styled.div`
//   flex: 0 0 40%;
//   max-width: 40%;
//   padding: 0 10px;
//   input {
//     margin: 0 0 20px;
//     &[type="button"] {
//       border-radius:60px;
//       background: none;
//       border: 4px solid #fff;
//       font-weight: bold;
//       color: #fff;
//       font-size: 18px;
//     }
//   }  
// `

// const FormRight = styled.div`
//   flex: 0 0 60%;
//   max-width: 60%;
//   padding: 0 10px;
//   textarea {
//     min-height: 303px
//   }
// `

// const FormDisclaimer = styled.p`
//   font-size: ${rem('14px')};
//   font-weight: 100;
//   letter-spacing: 0;
//   margin-top: ${rem('45px')};
// `
import React from 'react'
import { StaticQuery, graphql } from 'gatsby'

import Layout from '../layouts'
import Slider from '../components/Slider'
import Homepage from '../components/Homepage'

const IndexPage = () => (
  <StaticQuery
    query={graphql`
      query HomeQuery {
        wordpressPage(slug: {eq: "home"}) {
          title
          yoast {
            title
            metadesc
            opengraph_title
            opengraph_description
            opengraph_image {
              localFile {
                publicURL
              }
            }
            twitter_title
            twitter_description
            twitter_image {
              localFile {
                publicURL
              }
            }
          }
        }
      }
    `}
    render={data => (
      <Layout 
        title={data.wordpressPage.yoast.title ? data.wordpressPage.yoast.title : data.wordpressPage.title}
        seo={data.wordpressPage.yoast}
        location="/"
        homepage
        nofooter
      >
        <Slider />
        <Homepage /> 
      </Layout>      
    )}
  />
)

export default IndexPage

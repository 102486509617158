import React from 'react'
import styled from 'styled-components'
import { rem, rgba, cover } from 'polished'
import { Controller, Scene } from 'react-scrollmagic'
import { Tween } from 'react-gsap'

import { MainColour, DarkColour, AltFont, PDBold } from '../../../utils/variables'

class HomepageBackground extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isMobile: typeof window !== 'undefined' && window.innerWidth < 767,
      isTablet: typeof window !== 'undefined' && window.innerWidth < 1199,
    };
  }

  render() {

    const { isMobile, isTablet } = this.state

    const offset = isMobile ? 0 : (isTablet ? 50 : 250)
    const duration = isMobile ? 1400 : (isTablet ? 250 : 800)

    const backgroundReverse = isMobile ? true : false

    const leftAmount = isMobile ? '0%' : (isTablet ? '-35%' : '-45%')

    return (
      <SectionBackground>
        <Controller>
          <Scene
            duration={duration}
            offset={offset}
            triggerElement={null}
            reverse={backgroundReverse}
          // indicators={true}
          >
            {(progress) => (
              <Tween
                from={{ left: '-100%' }}
                to={{ left: leftAmount }}
                totalProgress={progress}
                paused
              >
                <DarkBlock>
                  <BackgroundText>Litigation</BackgroundText>
                </DarkBlock>
              </Tween>
            )}
          </Scene>
        </Controller>
      </SectionBackground>
    )
  }
}

export default HomepageBackground

export const SectionBackground = styled.div`
  ${cover()}
  background-color: ${MainColour};
  z-index: -1;
`

const DarkBlock = styled.div`
  background-color: ${DarkColour};
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;  
  z-index: 2;
  /* transform: translate(-50%,0); */
  @media (min-width: 768px) {
    transform: skewX(7.5deg);
  }
  @media (min-width: 992px) {
    transform: skewX(15deg);
  }
`

const BackgroundText = styled.div`
  font-family: ${AltFont};
  font-size: ${rem('280px')};
  font-weight: ${PDBold};
  font-style: italic;
  color: ${rgba('#fff', 0.05)};
  transform: skewX(-15deg) rotate(75deg);
  position: absolute;
  right: -685px;
  top: 35%;
  letter-spacing: -12px;
  display: none;
  @media (min-width: 992px) {
    display: inline-block;
  }
`

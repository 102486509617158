import React from 'react'
import styled from 'styled-components'
import { rem } from 'polished'
import { Controller, Scene } from 'react-scrollmagic'
import { Tween } from 'react-gsap'
import Tilt from 'react-tilt'

import { Container, Row, Col } from '../../Container'
import { ButtonLink } from '../../Button'

import { HorizontalLine, CircleEndpoint } from './'

import { AltFont } from '../../../utils/variables'

class Seo extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isTablet: typeof window !== 'undefined' && window.innerWidth < 1199,
    };
  }

  render() {
    const data = this.props.data

    const { isTablet } = this.state

    return (
      <>
        {!isTablet && <SeoLines />}
        <SeoContent data={data} />
      </>
    )
  }
}

export default Seo

const SeoLines = () => (
  <Container>
    {/* Lines */}
    <Controller>
      <Scene
        duration={100}
        offset={3300}
        reverse={false}
        triggerElement={null}
        // indicators={true}
      >
        {(progress) => (
          <Tween
            from={{
              width: '0'
            }}
            to={{
              width: '320px'
            }}
            totalProgress={progress}
            paused
          >
            <HorizontalLine style={{ marginRight: '4px', marginTop: '120px', right: '50%' }} />
          </Tween>
        )}
      </Scene>
      <Scene
        duration={200}
        offset={3500}
        reverse={false}
        triggerElement={null}
        // indicators={true}
      >
        {(progress) => (
          <Tween
            from={{
              transform: 'scale(0)',
              opacity: '0'
            }}
            to={{
              transform: 'scale(1)',
              opacity: '1'
            }}
            totalProgress={progress}
            paused
          >
            <CircleEndpoint style={{ marginRight: '334px', marginTop: '90px', right: '50%' }} />
          </Tween>
        )}
      </Scene>
    </Controller>
  </Container>
)

const SeoContent = (props) => (
  <SeoOuter>
    <Container>
      {/* SEO Content */}
      <Row justify="flex-start">
        <Col xl={{ width: "40%" }}>
          <Tilt className="tilt" options={{ max: 10, scale: 1.01 }} >
            <Controller>
              <Scene
                duration={200}
                offset={0}
                reverse={false}
              // indicators={true}
              >
                <Tween
                  staggerFrom={{
                    opacity: 0,
                    cycle: {
                      rotationX: [-90, 90],
                    }
                  }}
                  stagger={0.1}
                >
                  <SeoTitle dangerouslySetInnerHTML={{ __html: props.data.title }} />
                  <SeoText dangerouslySetInnerHTML={{ __html: props.data.text }} />
                  <SeoButton><ButtonLink to={props.data.button_link} primary>{props.data.button_text}</ButtonLink></SeoButton>
                  {/* <SeoTitle dangerouslySetInnerHTML={{ __html: 'Search <span class="alt">engine</span> <span class="large">optimization</span>' }} />
                  <SeoText dangerouslySetInnerHTML={{ __html: 'Donec sed eros augue. Aliquam aliquet velit eget massa luctus, quis consectetur ex interdum. Cras nibh enim, vulputate nec turpis ac, tincidunt molestie nibh. Aliquam a cursus felis. Sed vestibulum id quam feugiat efficitur. Etiam rhoncus pretium quam at cursus. Donec ac porttitor neque. In venenatis at lectus non tempus.' }} />
                  <SeoButton><ButtonLink to="/" primary>Learn More</ButtonLink></SeoButton> */}
                </Tween>
              </Scene>
            </Controller>
          </Tilt>
        </Col>
      </Row>
    </Container>
  </SeoOuter>
)

const SeoOuter = styled.div`
  display: inline-block;
  width: 100%;
  clear: both;
`

export const SeoTitle = styled.h4`
  font-size: ${rem('50px')};
  padding: 0;
  margin: ${rem('30px')} 0;
  @media (min-height: 768px) {
    margin: 0 0 ${rem('45px')} 0;
  }
  position: relative;
  font-style: italic;
  .alt {
    font-family: ${AltFont};
    font-style: italic;
  }
  .large {
    display: block;
    font-size: ${rem('55px')};
    font-style: normal;
  }
  @media (min-width: 576px) {
    font-size: ${rem('70px')};
    .large {
      font-size: ${rem('75px')};
    }
  }
  @media (min-width: 1200px) {
    padding: ${rem('210px')} 0 0 0;
    text-align: right;
  }  
  @media (min-width: 1400px) {
    font-size: ${rem('80px')};
    .large {
      font-size: ${rem('90px')};
    }
  }
  @media (min-width: 1600px) {
    font-size: ${rem('90px')};
    .large {
      font-size: ${rem('98px')};
    }
  }
`

export const SeoText = styled.p`
  font-size: ${rem('17px')};
  margin: 0 0 ${rem('30px')} 0;
  @media (min-height: 768px) {
    margin: 0 0 ${rem('45px')} 0;
  }
  @media (min-width: 576px) {
    font-size: ${rem('19px')};
  }
  @media (min-width: 1200px) {
    text-align: right;
  }  
  @media (min-width: 1600px) {
    font-size: ${rem('22px')};
  }
`

export const SeoButton = styled.p`
  @media (min-width: 1200px) {
    text-align: right;
  }  
`
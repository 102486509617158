import React from 'react'
import styled from 'styled-components'
import { rem } from 'polished'
import { Controller, Scene } from 'react-scrollmagic'
import { Tween, Timeline } from 'react-gsap'

import Automation from './Automation'
import Web from './Web'
import Footer from './Footer'
import Mobile from './Mobile'

import { DarkColour } from '../../utils/variables';

class Homepage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isSmallMobile: typeof window !== 'undefined' && window.innerWidth < 576,
      isMobile: typeof window !== 'undefined' && window.innerWidth < 768,
      isTablet: typeof window !== 'undefined' && window.innerWidth < 1199
    };
  }
  render() {
    const { isTablet, isSmallMobile, isMobile } = this.state
    const footerOffset = isSmallMobile ? -420 : (isTablet ? -450 : -300)
    return (
      <>
        {isMobile ? 
          <Mobile />
        : (
          <HomepageSections>
            <Controller>
              <Scene
                triggerHook="onLeave"
                duration="200%"
                offset="1200"
                pin
              // indicators={true}
              >
                <Timeline
                  wrapper={<div id="pinContainer" />}
                >
                  <Automation />
                  <Tween
                    from={{ y: '100%' }}
                    to={{ y: '0%' }}
                  >
                    <WebSection>
                      <Web />
                    </WebSection>
                  </Tween>
                </Timeline>
              </Scene>
              <Scene
                triggerHook="onEnter"
                duration="500"
                offset={footerOffset}
              // pin
              // indicators={true}
              >
                <Timeline
                  wrapper={<div id="pinContainer2" />}
                >
                  <Tween
                    from={{ y: '100%' }}
                    to={{ y: '0%' }}
                  >
                    <FooterSection>
                      <Footer />
                    </FooterSection>
                  </Tween>
                </Timeline>
              </Scene>
            </Controller>
          </HomepageSections>
        )}        
      </>
    )
  }
}

export default Homepage

const HomepageSections = styled.div`
  overflow: hidden;
  padding-bottom: 0;
  #pinContainer, #pinContainer2 {
    height: 100%;
    width: 100vw;
    overflow: hidden;
  }
  @media (min-width: 1200px) {
    padding-bottom: 700px;
  }
`

const WebSection = styled.div`
  /* padding: ${rem('250px')} 0 ${rem('500px')};   */
  width: 100vw;
  position: absolute;
  background-color: ${DarkColour};
  z-index: 200;
  bottom: 0;
  left: 0;  
  min-height: 1300px; 
  @media (min-width: 576px) {
    min-height: 1350px; 
  }
  @media (min-width: 992px) {
    min-height: 1300px; 
  }
  @media (min-width: 1200px) {
    min-height: 1800px; 
    max-height: 1800px;
    margin-bottom: 250px;
    bottom: -250px;
  }  
  @media (min-width: 1400px) {
    min-height: 2125px;
    max-height: 2125px;
  }
`

const FooterSection = styled.div`
  min-height: 100vh;
  width: 100vw;
  position: fixed;
  z-index: 600;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  visibility: hidden;
  animation: hideFooter 0s 3s forwards;
`
import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'
import { rgba, cover } from 'polished'
import { Controller, Scene } from 'react-scrollmagic'
import { Tween, Timeline } from 'react-gsap'

import { MainColour } from '../../../utils/variables'

const query = graphql`
  query webImageQuery {
    wordpressPage(wordpress_id: { eq: 308}) {  
      acf {
        digital_marketing_section {
          images {
            background_image {              
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1920, maxHeight: 2160) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            top_image {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1170, maxHeight: 1020) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            bottom_image {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 690, maxHeight: 1441) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

function imageData(WrappedComponent) {
  return props => (
    <StaticQuery
      query={query}
      render={data =>
        <WrappedComponent {...props} data={data} />
      }
    />
  )
}

export const ParallaxBg = imageData( props => (
  <ParallaxBackground>
    <Controller>
      <Scene
        // indicators={true}
        offset={-340}
        duration="250%"
        triggerHook="onEnter"
      >
        <Timeline
          wrapper={<div className="parallax" />}
        >
          <BackgroundOverlay />
          <Tween
            // position="0"
            from={{
              yPercent: props.parallaxStart,
            }}
            to={{
              yPercent: props.parallaxEnd,
            }}
          >
            <div className="parallaxImage">
              <Img fluid={props.data.wordpressPage.acf.digital_marketing_section.images.background_image.localFile.childImageSharp.fluid} />
            </div>
          </Tween>
        </Timeline>
      </Scene>
    </Controller>
  </ParallaxBackground>
))
  

export default ParallaxBg

const ParallaxBackground = styled.div`
  .parallax {
    ${cover()};
    overflow: hidden;
    .parallaxImage {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      bottom: 0;
      .gatsby-image-wrapper {
        min-height: 100%;
      }
    }
  }
`

const BackgroundOverlay = styled.div`
  ${cover()};
  background-color: ${rgba(MainColour, 0.6)};
  z-index: 250;
`

export const LaptopImage = imageData( props => (
  <LaptopOuter>
    <Controller>
      <Scene
        duration={500}
        offset={2300}
        triggerElement={null}
      // reverse={false}
      // indicators={true}
      >
        {(progress) => (
          <Tween
            from={{
              transform: 'translate(-50%,0)',
              opacity: '0'
            }}
            to={{
              transform: 'translate(0,0)',
              opacity: '1'
            }}
            totalProgress={progress}
            paused
          >
            <div className="laptopImage">
              <Img fluid={props.data.wordpressPage.acf.digital_marketing_section.images.top_image.localFile.childImageSharp.fluid} />
            </div> 
          </Tween>
        )}
      </Scene>
    </Controller>
  </LaptopOuter>
))

const LaptopOuter = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 300;
  max-width: 65%;
  display: none;
  .laptopImage {
    height: 1020px;
    width: 1170px;
    max-width: 100%;
  }
  @media (min-width: 1200px) {
    display: inline-block;
  }
`

export const SeoImage = imageData( props => (
  <PhoneImage>
    <Controller>
      <Scene
        duration={500}
        offset={3000}
        triggerElement={null}
      // reverse={false}
      // indicators={true}
      >
        {(progress) => (
          <Tween
            from={{
              transform: 'translate(0,100%)',
              opacity: '0'
            }}
            to={{
              transform: props.phonePosition,
              opacity: '1'
            }}
            totalProgress={progress}
            paused
          >
            <div className="phoneImage">
              <Img fluid={props.data.wordpressPage.acf.digital_marketing_section.images.bottom_image.localFile.childImageSharp.fluid} />
            </div>
          </Tween>
        )}
      </Scene>
    </Controller>
  </PhoneImage>
))

const PhoneImage = styled.div`
  position: absolute;
  bottom: 0;
  right: 30px;
  z-index: 300;
  max-width: 45%;
  .phoneImage {
    height: 690px;
    width: 1441px;
    max-width: 100%;
  }
  @media (min-width: 1600px) {
    right: 110px;
    max-width: 39%;
  }
`
import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'
import { cover } from 'polished'

const FooterBackground = () => (
  <StaticQuery
    query={graphql`
      query footerBackgroundQuery {
        file(relativePath:{eq:"images/footer-background.jpg"}) {
          childImageSharp {
            fluid(maxWidth: 1920, maxHeight: 966) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data =>
      <BackgroundImage>
        <Img fluid={data.file.childImageSharp.fluid} />
      </BackgroundImage>
    }
  />
)

export default FooterBackground

const BackgroundImage = styled.div`
  ${cover()};
  opacity: 0.05;
  .gatsby-image-wrapper {
    min-height: 100%;
  }
  @media (min-width: 768px) {
    .gatsby-image-wrapper {
      ${cover()};
      min-height: 100vh;
    }
  }  
`
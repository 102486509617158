import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'

import Web from './web'
import Seo from './seo'

const WebDev = () => (
  <StaticQuery
    query={graphql`
      query digitalMarketingQuery {
        wordpressPage(wordpress_id: { eq: 308}) {  
          acf {
            digital_marketing_section {
              web_development {
                title
                text
                button_text
                button_link
              }
              seo {
                title
                text
                button_text
                button_link
              }
            }
          }
        }
      }
    `}
    render={data =>
      <WebDevOuter>
        <Web data={data.wordpressPage.acf.digital_marketing_section.web_development} />
        <Seo data={data.wordpressPage.acf.digital_marketing_section.seo} />
      </WebDevOuter>
    }
  />
)

export default WebDev

const WebDevOuter = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 300;
  @media (min-width: 1200px) {
    top: 120px;
  }
  @media (min-width: 1400px) {
    top: auto;
  }
`
import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import { rem } from 'polished'

import AutomationBackground from './background'
import AutomationIntro from './intro'
import Icons from './icons'
import AutomationFooter from './footer'

export default () => (
  <StaticQuery 
    query={graphql`
      query automationQuery {
        wordpressPage(wordpress_id: { eq: 308}) {  
          acf {
            automation_section {
              introduction {
                title
                first_paragraph
                second_paragraph
              }        
              icons {
                label
                icon {
                  localFile {
                    publicURL
                  }
                }                
              }
              footer {
                title
                text
                button_text
                button_link
              }
            }
          }
        }
      }
    `}
    render={ data => 
      <AutomationSection>
        <AutomationBackground />
        <AutomationIntro data={data.wordpressPage.acf.automation_section.introduction} />
        <Icons data={data.wordpressPage.acf.automation_section.icons} />
        <AutomationFooter data={data.wordpressPage.acf.automation_section.footer} />
      </AutomationSection>
    }
  />  
)

const AutomationSection = styled.div`
  padding: ${rem('45px')} 0;  
  position: relative;
  @media (min-width: 768px) {
    padding: ${rem('90px')} 0; 
  }
  @media (min-width: 1200px) {
    padding: ${rem('150px')} 0;
    min-height: 2184px;
    max-height: 2184px;
  }
`
import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import { rem } from 'polished'
import SVG from 'react-inlinesvg'
import AniLink from 'gatsby-plugin-transition-link/AniLink'

import { Container, Row } from '../../Container'
import { ButtonLink } from '../../Button'

import { AutomationTitle, Intro, Text } from '../Automation/intro'
import { IconList, IconCircle } from '../Automation/icons'
import { FooterContent } from '../Automation/footer'

import { ParallaxBg } from '../Web/images'
import { WebDevTitle, WebDevText, Icon } from '../Web/web'
import { SeoTitle, SeoText, SeoButton } from '../Web/seo'

import FooterBackground from '../Footer/background'
import { FooterIconList, FooterIconCircle } from '../Footer/details'
import { HomepageCopyright } from '../Footer/copyright' 

import { LinkAnimation } from '../../Menu'

import { MainColour, DarkColour } from '../../../utils/variables'

import WordpressLogo from '../../../images/web-wordpress.svg'
import ReactLogo from '../../../images/web-react.svg'
import GatsbyLogo from '../../../images/web-gatsby.svg'
import IconPhone from '../../../images/footer-phone.svg'
import IconAddress from '../../../images/footer-address.svg'
import IconEmail from '../../../images/footer-email.svg'

export default () => (
  <StaticQuery
    query={graphql`
      query homepageMobileQuery {
        wordpressPage(wordpress_id: { eq: 308}) {  
          acf {
            mobile_quick_links {
              title
              link
              icon {
                localFile {
                  publicURL
                }
              }
            }
            automation_section {
              introduction {
                title
                first_paragraph
                second_paragraph
              }        
              icons {
                label
                icon {
                  localFile {
                    publicURL
                  }
                }                
              }
              footer {
                title
                text
                button_text
                button_link
              }
            }
            digital_marketing_section {
              web_development {
                title
                text
                button_text
                button_link
              }
              seo {
                title
                text
                button_text
                button_link
              }
            }
          }
        }
        wordpressAcfOptions {
          options {
            general {
              address
              post_code
              phone_number
              email_address
            }
          }
        }
      }
    `}
    render={data =>
      <MobileHomepage id="homepageContent">
        <QuickLinks 
          data={data.wordpressPage.acf.mobile_quick_links}
        />
        <Automation
          intro={data.wordpressPage.acf.automation_section.introduction}
          icons={data.wordpressPage.acf.automation_section.icons}
          footer={data.wordpressPage.acf.automation_section.footer}
        />
        <DigitalMarketing
          web={data.wordpressPage.acf.digital_marketing_section.web_development}
          seo={data.wordpressPage.acf.digital_marketing_section.seo}
        />
        <Footer
          details={data.wordpressAcfOptions.options.general}
        />
      </MobileHomepage>      
    }
  />
)

const MobileHomepage = styled.div``

const QuickLinks = (props) => (
  <LinksOuter>
    <Row align="center" justify="space-between">
      {props.data.map((item, index) =>
        <SingleLink key={index}>
          <AniLink
            to={item.link}
            cover
            bg={LinkAnimation}
            direction="right"
            duration={2}
          >
            <IconButton text={item.title}>
              <SVG src={item.icon.localFile.publicURL} />
            </IconButton>
          </AniLink>
        </SingleLink>
      )}
    </Row>    
  </LinksOuter>
)

const LinksOuter = styled.div`
  width: 100%;
  padding: ${rem('60px')} ${rem('30px')} ${rem('105px')};  
  background-color: ${DarkColour};
  position: relative;
  &:before {
    content: '';
    width: 100%;
    height: 90px;
    background: linear-gradient(0deg, rgba(3,11,12,1) 20%, rgba(3,11,12,0) 100%);
    position: absolute;
    top: -90px;
    left: 0;
  }
`

const SingleLink = styled.div`
  padding: 0 ${rem('15px')};
  flex: 0 0 100%;
  max-width: 100%;
  text-align: center;
  margin: ${rem('75px')} 0 0 0;
  &:nth-child(-n+1) {
    margin: 0;
  }
  @media (min-width: 300px) {
    flex: 0 0 50%;
    max-width: 50%;
    &:nth-child(-n+2) {
      margin: 0;
    }
  }
  @media (min-width: 576px) {
    flex: 0 0 25%;
    max-width: 25%;
    &:nth-child(-n+4) {
      margin: 0;
    }
  } 
`

const IconButton = styled.div`
  width: 100%;
  height: 100%;
  padding-top: 100%;
  border-radius: 300px;
  background-color: ${MainColour};
  position: relative;
  &:after {
    content: '${props => props.text}';
    position: absolute;
    bottom: -45px;
    left: 0;
    width: 100%;
    text-align: center;
    color: #fff;
  }
  span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    line-height: 0;
    height: 100%;
    width: 100%;
  }
  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 40%;
  }  
`

const Automation = (props) => (  
  <AutomationSection>
    <Container>
      <AutomationTitle dangerouslySetInnerHTML={{ __html: props.intro.title }} />
      <Intro dangerouslySetInnerHTML={{ __html: props.intro.first_paragraph }} />
      <Text dangerouslySetInnerHTML={{ __html: props.intro.second_paragraph }} />
      <Row align="center" justify="center">
        <IconList>
          {props.icons.map((icon, index) =>
            <IconCircle key={index} text={icon.label}>
              <SVG src={icon.icon.localFile.publicURL} />
            </IconCircle>
          )}
        </IconList>
      </Row>      
      <FooterContent>
        <h3 dangerouslySetInnerHTML={{ __html: props.footer.title }} />
        <p dangerouslySetInnerHTML={{ __html: props.footer.text }} />
        <p><ButtonLink to={props.footer.button_link} primary>{props.footer.button_text}</ButtonLink></p>
      </FooterContent>
    </Container>
  </AutomationSection>      
)
 
const AutomationSection = styled.div`
  background-color: ${MainColour};
  padding: ${rem('60px')} 0;
  position: relative;
  text-align: center;
`

const DigitalMarketing = (props) => (
  <DigitalMarketingSection>
    <ParallaxBg />
    <WebSection>
      <Container>
        <WebDevTitle dangerouslySetInnerHTML={{ __html: props.web.title }} />
        <WebDevText dangerouslySetInnerHTML={{ __html: props.web.text }} />
        <p><ButtonLink to={props.web.button_link} primary>{props.web.button_text}</ButtonLink></p>
        <Row align="center" justify="center">
          <WebIconList>
            <Icon><SVG src={WordpressLogo} /></Icon>
            <Icon><SVG src={ReactLogo} /></Icon>
            <Icon><SVG src={GatsbyLogo} /></Icon>
          </WebIconList>
        </Row>        
      </Container>
    </WebSection> 
    <SeoSection>
      <Container>
        <SeoTitle dangerouslySetInnerHTML={{ __html: props.seo.title }} />
        <SeoText dangerouslySetInnerHTML={{ __html: props.seo.text }} />
        <SeoButton><ButtonLink to={props.seo.button_link} primary>{props.seo.button_text}</ButtonLink></SeoButton>
      </Container>
    </SeoSection>
  </DigitalMarketingSection>  
)

const DigitalMarketingSection = styled.div`
  background-color: ${DarkColour};
  padding: ${rem('60px')} 0;
  position: relative;
  text-align: center;
`

const WebSection = styled.div`
  position: relative;
  z-index: 300;
`

const WebIconList = styled.div`
  display: inline-block;    
  opacity: 0.6;
  margin: ${rem('30px')} auto ${rem('60px')};
`

const SeoSection = styled.div`
  position: relative;
  z-index: 300;
`

const Footer = (props) => (
  <FooterSection>
    <FooterBackground />
    <Container>
      <Row align="center" justify="center">
        <FooterIconList>
          <FooterIconCircle text={props.details.phone_number}>
            <a href={"tel:" + props.details.phone_number} aria-label={"Call us on: " + props.details.phone_number}>
              <SVG src={IconPhone} />
            </a>
          </FooterIconCircle>
          <FooterIconCircle text={props.details.address + ' ' + props.details.post_code} hidden768>
            <SVG src={IconAddress} />
          </FooterIconCircle>
          <FooterIconCircle text={props.details.email_address}>
            <a href={"mailto:" + props.details.email_address} aria-label={"Email us at: " + props.details.email_address}>
              <SVG src={IconEmail} />
            </a>
          </FooterIconCircle>
        </FooterIconList>
      </Row>   
      <ButtonLink to="/get-in-touch" primary>Enquire Online</ButtonLink>  
      <HomepageCopyright /> 
    </Container>    
  </FooterSection>
)

const FooterSection = styled.div`
  position: relative;
  padding: ${rem('60px')} 0;
  background-color: #222;
  overflow: hidden;
  text-align: center;
`

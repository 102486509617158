import React from 'react'
import styled from 'styled-components'
import { rem } from 'polished'
import { Controller, Scene } from 'react-scrollmagic'
import { Tween } from 'react-gsap'

import Details from './details'
import Form from './form'
import Copyright from './copyright'
import FooterBackground from './background'
import { ButtonLink } from '../../Button'

class HomepageFooter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      is860Height: typeof window !== 'undefined' && window.innerHeight < 860,
      isSmallMobile: typeof window !== 'undefined' && window.innerWidth < 576,
      isMobile: typeof window !== 'undefined' && window.innerWidth < 767,
      isTablet: typeof window !== 'undefined' && window.innerWidth < 1199,      
    };
  }
  render() {
    const { isTablet, isSmallMobile, isMobile, is860Height } = this.state
    const footerOffset = isTablet ? 2600 : 4400
    const topPosition = isTablet ? 0 : '-45%'
    const bottomPosition = isSmallMobile ? '50%' : (isTablet ? '45%' : '25%')
    return (
      <FooterContent>
        <FooterBackground />
        <Controller>
          <Scene
            duration={300}
            offset={footerOffset}
            // reverse={false}
            triggerElement={null}
            // indicators={true}
          >
            {(progress) => (
              <>
                <Tween
                  from={{
                    top: '0',
                  }}
                  to={{
                    top: topPosition,
                  }}
                  totalProgress={progress}
                  paused
                >
                  <FooterTop>
                    <Details />
                  </FooterTop>
                </Tween>
                <Tween
                  from={{
                    opacity: '0',
                    top: '100%'
                  }}
                  to={{
                    opacity: '1',
                    top: bottomPosition
                  }}
                  totalProgress={progress}
                  paused
                >
                  <FooterBottom>
                    {!is860Height ? (
                      <>{!isMobile && <Form />}</>
                    ):(
                      <ButtonLink to="/get-in-touch" primary>Enquire Online</ButtonLink>
                    )}                    
                    <Copyright />
                  </FooterBottom>
                </Tween>
              </>
            )}
          </Scene>
        </Controller>
      </FooterContent>
    )
  }
}

export default HomepageFooter

const FooterContent = styled.div`
  background-color: #222;
  height: 100%;
  width: 100%;
  position: relative;
`

const FooterTop = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
`

const FooterBottom = styled.div`
  position: fixed;
  bottom: 30px;
  width: 100%;
  padding: 0 ${rem('15px')};
  text-align: center;
  @media (min-width: 576px) {
    position: absolute;
    left: 50%;
    transform: translate(-50%,0);
    width: 1024px;
    max-width: 90%;
    padding: 0;
  }
`
import { createGlobalStyle } from 'styled-components'
import { position, hideText } from 'polished'

import ArrowLeft from '../../images/icon-angle-left.svg'
import ArrowRight from '../../images/icon-angle-right.svg'

const SliderStyle = createGlobalStyle`

  .slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-touch-callout: none;
    user-select: none;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
    min-height: 100vh;
    &:hover {
      .slick-arrow {
        opacity: 0.2;
        &.slick-next {
          right: 0;
          background-image: url(${ArrowRight});
        }
        &.slick-prev {
          left: 0;
          background-image: url(${ArrowLeft});
        }
      }
    }
    @media (min-width: 768px) {
      min-height: initial;
    }
  }

  .slick-list {
    position: relative;
    overflow: hidden;
    display: block;
    margin: 0;
    padding: 0;
    &:focus {
      outline: none;
    }
    &.dragging {
      cursor: pointer;
      cursor: hand;
    }
  }

  .slick-slider .slick-track, .slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  .slick-track {
    position: relative;
    left: 0;
    top: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;
    &:before, &:after {
        content: "";
        display: table;
    }
    &:after {
        clear: both;
    }
    &.slick-loading {
        visibility: hidden;
    }
  }
  .slick-slide {
    position: relative;
    float: left;
    min-height: 100vh;
    &[dir="rtl"] {
      float: right;
    }
    img {
      display: block;
    }
    .videoPlaceholder {
      max-width: initial;
      min-height: 100vh;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
    }
    &.slick-loading img {
      display: none;
    }    
    &.dragging img {
      pointer-events: none;
    }
    &.slick-initialized {
      display: block;
    }
    &.slick-loading {
      visibility: hidden;
    }
    &.slick-vertical {
      display: block;
      height: auto;
      border: 1px solid transparent;
    }
    & > div {
      line-height: 0;
      div {
        line-height: initial;
      }
    }
    .isvg {
      position: absolute;
      bottom: 60px;
      left: 50%;
      transform: translate(-50%,0);
      z-index: 5;
      display: none;
      svg {
        animation: bounceArrow 2s infinite;
        path {
          stroke-dashoffset: 1001;
          stroke-dasharray: 1000;
          animation: drawArrow 10s linear forwards;
        }
      }
    }
    .gatsby-image-wrapper {
      min-height: 100vh;
    }
    @media (min-width: 768px) {
      .gatsby-image-wrapper {
        min-height: initial;
      }
    }
    @media (min-height: 576px) {
      .isvg {
        display: inline-block;
      }      
    }
    @media (min-width: 1200px) {
      .gatsby-image-wrapper {
        min-height: 100vh;
      }
    }
  }

  .slick-arrow.slick-hidden {
    display: none;
  }

  .slick-arrow {
    width: 5%;
    height: 100%;
    z-index: 500;
    background-size: 8px;
    ${position('absolute', '50%', '', '', '')};
    transform: translate(0,-50%);
    background-color: transparent;
    border: none;
    ${hideText()};
    color: transparent;
    cursor: pointer;
    background-repeat: no-repeat;
    background-position: center center;
    opacity: 0;
    transition: all 0.3s ease;
    &.slick-next {
      right: -5%;
      background-image: url(${ArrowRight});
    }
    &.slick-prev {
      left: -5%;
      background-image: url(${ArrowLeft});
    }
    &:hover, &:active, &:focus {
      opacity: 0.4;
    }
  }

  .slick-dots {
    display: block;
    ${position('absolute', '', '', '30px', '50%')};
    transform: translate(0,-50%);
    margin:auto;
    height:20px;		
    width:auto;
    z-index: 300;
    opacity: 0.1;
    transition: all 0.3s ease;
    &:hover, &:active, &:focus {
      opacity: 0.6;
    }
    li {      
      float: left;
      margin: 0 5px 0 0;
      &:last-child {
        margin: 0;
      }
      button {
        display: inline-block;
        vertical-align: top;
        width: 12px;
        height: 12px;
        margin: 0; 
        padding: 0;
        border: 1px solid #fff;
        overflow: hidden;
        border-radius: 100%;
        background: transparent;
        ${hideText()};
        cursor: pointer;
      }
      &.slick-active {
        button {
          background: #fff;
        }            
      }
    }
  }

`

export default SliderStyle
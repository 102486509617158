import React from 'react'
import styled from 'styled-components'
import { rgba, cover, rem } from 'polished'

import Background from './background'
import WebDev from './content'
import { SeoImage } from './images'

import { MainColour } from '../../../utils/variables'

class DigitalMarketing extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isTablet: typeof window !== 'undefined' && window.innerWidth < 1199,
      isSmall: typeof window !== 'undefined' && window.innerWidth < 1399,
    };
  }

  render() {

    const { isSmall, isTablet } = this.state

    const phonePosition = isSmall ? 'translate(0,-20%)' : 'translate(0,-35%)'

    return (
      <WebSection>
        <Background />
        <WebDev />
        {!isTablet && <SeoImage phonePosition={phonePosition} />}
      </WebSection> 
    )
  }
}

export default DigitalMarketing

const WebSection = styled.div`
  ${cover()};
  padding: ${rem('250px')} 0 ${rem('500px')};
`

export const HorizontalLine = styled.div`
  height: 0px;
  border-top: 2px dashed ${rgba('#fff', 0.2)};
  position: absolute;
  z-index: 500;
  /* transform: translate(-50%,0); */
  /* transform-origin: 0 0; */
`

export const CircleEndpoint = styled.div`
  height: 60px;
  width: 60px;
  background-color: ${rgba('#fff', 0.4)};
  border-radius: 60px;
  position: absolute;
  &:before, &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
  }
  &:before {    
    height: 10px;
    width: 10px;
    background-color: ${MainColour};
    border-radius: 10px;
  }
  &:after {
    height: 80px;
    width: 80px;
    border: 2px solid ${rgba('#fff', 0.4)};
    border-radius: 80px;
  }
`
import React from 'react'
import styled from 'styled-components'
import { rem } from 'polished'
import { Controller, Scene } from 'react-scrollmagic'
import { Tween } from 'react-gsap'

import { Container, Row, Col } from '../../Container'

import { AltFont } from '../../../utils/variables'

class HomepageIntro extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isMobile: typeof window !== 'undefined' && window.innerWidth < 767,
      isTablet: typeof window !== 'undefined' && window.innerWidth < 1199,
    };
  }

  render() {

    const data = this.props.data
    const { isMobile, isTablet } = this.state

    const titleDuration = isMobile ? 200 : (isTablet ? 300 : 500)
    const titleOffset = isMobile ? -200 : (isTablet ? 100 : 700)

    // const textOffset = isMobile ? -200 : 100

    // console.log("Tablet: " + isTablet + ", Mobile: " + isMobile)

    return (
      <SectionContent>
        <Container>
          <Row align="center" justify="space-between">
            <Col md={{ width: '60%' }} lg={{ width: '40%' }} xl={{ width: "35%" }}>
              <Controller>
                <Scene
                  duration={titleDuration}
                  offset={titleOffset}
                  triggerElement={null}
                  reverse={false}
                // indicators={true}
                >
                  {(progress) => (
                    <Tween
                      from={{
                        transform: 'translate(-50%,0)',
                        opacity: '0'
                      }}
                      to={{
                        transform: 'translate(0,0)',
                        opacity: '1'
                      }}
                      totalProgress={progress}
                      paused
                    >
                      <AutomationTitle dangerouslySetInnerHTML={{ __html: data.title }} />
                      {/* <AutomationTitle dangerouslySetInnerHTML={{ __html: 'Automate <span class="alt">your</span> <span class="large">business</span>' }} /> */}
                    </Tween>
                  )}
                </Scene>
              </Controller>
            </Col>
            <Col xl={{ width: '50%' }} xxl={{ width: "42%" }}>
              <Controller>
                <Scene
                  duration={200}
                  offset={100}
                  reverse={false}
                // indicators={true}
                >
                  <Tween
                    staggerFrom={{
                      opacity: 0,
                      cycle: {
                        rotationX: [-90, 90],
                      }
                    }}
                    stagger={0.1}
                  >
                    <Intro dangerouslySetInnerHTML={{ __html: data.first_paragraph }} />
                    <Text dangerouslySetInnerHTML={{ __html: data.second_paragraph }} />
                    {/* <Intro>Nulla non ligula efficitur, venenatis leo a, aliquam eros. Vivamus vitae dictum nisi. Aliquam at arcu orci. Cras sapien mauris, facilisis ut enim ut, pharetra interdum felis. Proin id ante ante. Maecenas sodales ultrices ornare. Duis commodo sem et volutpat malesuada. Duis elit mi, interdum id posuere ut, semper elementum turpis. Sed varius volutpat quam id maximus.</Intro>
                <Text>Donec efficitur velit erat, eget facilisis massa gravida eget. Morbi varius dolor ut lectus fringilla, in euismod sapien tempor. Donec massa tellus, cursus sit amet sem ut, pellentesque scelerisque purus. Morbi a consectetur ipsum. Sed metus risus, imperdiet in lorem vitae, vestibulum convallis nunc. Donec sed eros augue. Aliquam aliquet velit eget massa luctus, quis consectetur ex interdum.</Text> */}
                  </Tween>
                </Scene>
              </Controller>
            </Col>
          </Row>
        </Container>
      </SectionContent>
    )
  }
}

export default HomepageIntro


const SectionContent = styled.div`
  position: relative;
  z-index: 5;
`

export const AutomationTitle = styled.h2`
  font-size: ${rem('40px')};
  padding: 0;
  position: relative;
  margin: 0 0 ${rem('60px')};
  &:before {
    content: '';
    background-color: #fff;
    width: 50%;
    height: 4px;
    position: absolute;
    top: 0;
    left: 0;
    display: none;
  }
  .alt {
    font-family: ${AltFont};
    font-style: italic;
  }
  .large {
    font-size: ${rem('70px')};
  }
  @media (min-width: 576px) {
    font-size: ${rem('50px')};
    .large {
      font-size: ${rem('90px')};
    }
  }
  @media (min-width: 768px) {
    padding: ${rem('60px')} 0 0 0;
    &:before {
      display: inline-block;
    }
  }
  @media (min-width: 1200px) {
    padding: ${rem('90px')} 0 0 0;
    margin: 0;
  }
  @media (min-width: 1400px) {
    font-size: ${rem('60px')};
    .large {
      font-size: ${rem('110px')};
    }
  }
  @media (min-width: 1600px) {
    font-size: ${rem('75px')};
    .large {
      font-size: ${rem('126px')};
    }
  }
`

export const Intro = styled.p`
  font-family: ${AltFont};
  font-size: ${rem('21px')};
  margin: 0 0 ${rem('60px')};
  @media (min-width: 1200px) {
    margin: 0 0 ${rem('75px')};
  }
  @media (min-width: 1600px) {
    font-size: ${rem('22px')};
    margin: 0 0 ${rem('90px')};
  }  
`

export const Text = styled.p`
  font-size: ${rem('17px')};
  @media (min-width: 1200px) {
    padding: 0 0 0 ${rem('60px')};
  }
  @media (min-width: 1600px) {
    font-size: ${rem('20px')};
  }  
`
import React from 'react'
import styled from 'styled-components'
import { rem } from 'polished'
import { Controller, Scene } from 'react-scrollmagic'
import { Tween } from 'react-gsap'
import SVG from 'react-inlinesvg'
import Tilt from 'react-tilt'

import { Container, Row, Col } from '../../Container'
import { ButtonLink } from '../../Button'

import { HorizontalLine, CircleEndpoint } from './'

import { AltFont } from '../../../utils/variables'

import WordpressLogo from '../../../images/web-wordpress.svg'
import ReactLogo from '../../../images/web-react.svg'
import GatsbyLogo from '../../../images/web-gatsby.svg'

class WebDev extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isTablet: typeof window !== 'undefined' && window.innerWidth < 1199,
      isSmall: typeof window !== 'undefined' && window.innerWidth < 1399,
    };
  }

  render() {
    const data = this.props.data

    const { isSmall, isTablet } = this.state

    const lineOffset = isSmall ? 2400 : 2500
    const circleOffset = isSmall ? 2500 : 2600

    const textOffset = isSmall ? 0 : -200

    return (
      <Container>
        {!isTablet &&
          <WebDevLines
            lineOffset={lineOffset}
            circleOffset={circleOffset}
          />
        }        
        <WebDevContent 
          data={data}
          textOffset={textOffset}
        />
      </Container>
    )
  }
}

export default WebDev

const WebDevLines = (props) => (
  <>
    {/* Lines */}
    <Controller>
      <Scene
        duration={100}
        offset={props.lineOffset}
        reverse={false}
        triggerElement={null}
        // indicators={true}
      >
        {(progress) => (
          <Tween
            from={{
              width: '0'
            }}
            to={{
              width: '320px'
            }}
            totalProgress={progress}
            paused
          >
            <HorizontalLine style={{ marginLeft: '4px', left: '50%' }} />
          </Tween>
        )}
      </Scene>
      <Scene
        duration={200}
        offset={props.circleOffset}
        reverse={false}
        triggerElement={null}
        // indicators={true}
      >
        {(progress) => (
          <Tween
            from={{
              transform: 'scale(0)',
              opacity: '0'
            }}
            to={{
              transform: 'scale(1)',
              opacity: '1'
            }}
            totalProgress={progress}
            paused
          >
            <CircleEndpoint style={{ marginLeft: '334px', marginTop: '-30px', left: '50%' }} />
          </Tween>
        )}
      </Scene>
    </Controller>
  </>
)

const WebDevContent = (props) => (
  <>
    {/* Content + Logos */}
    <Row align="flex-end" justify="space-between">
      <Col xl={{ order: '2', width: "40%" }}>
        <Tilt className="tilt" options={{ max: 10, scale: 1.01 }} >
          <Controller>
            <Scene
              duration={200}
              offset={props.textOffset}
              reverse={false}
              // indicators={true}
            >
              <Tween
                staggerFrom={{
                  opacity: 0,
                  cycle: {
                    rotationX: [-90, 90],
                  }
                }}
                stagger={0.1}
              >
                <WebDevTitle dangerouslySetInnerHTML={{ __html: props.data.title }} />
                <WebDevText dangerouslySetInnerHTML={{ __html: props.data.text }} />
                <p><ButtonLink to={props.data.button_link} primary>{props.data.button_text}</ButtonLink></p>
                {/* <WebDevTitle dangerouslySetInnerHTML={{ __html: 'Web <span class="alt">design</span> <span class="small">and development</span>' }} />
                <WebDevText dangerouslySetInnerHTML={{ __html: 'Donec sed eros augue. Aliquam aliquet velit eget massa luctus, quis consectetur ex interdum. Cras nibh enim, vulputate nec turpis ac, tincidunt molestie nibh. Aliquam a cursus felis. Sed vestibulum id quam feugiat efficitur. Etiam rhoncus pretium quam at cursus. Donec ac porttitor neque. In venenatis at lectus non tempus.' }} />
                <p><ButtonLink to="/" primary>Learn More</ButtonLink></p> */}
              </Tween>
            </Scene>
          </Controller>
        </Tilt>
      </Col>
      <Col xl={{ order: '1', width: "45%" }}>
        <WebDevIcons>
          <IconList>
            <Controller>
              <Scene
                duration={300}
                offset={-400}
                reverse={false}
              // indicators={true}
              >
                <Tween
                  staggerFrom={{
                    opacity: 0,
                    cycle: {
                      scale: [0, 0.5],
                    }
                  }}
                  stagger={0.1}
                >
                  <Icon><SVG src={WordpressLogo} /></Icon>
                  <Icon><SVG src={ReactLogo} /></Icon>
                  <Icon><SVG src={GatsbyLogo} /></Icon>
                </Tween>
              </Scene>
            </Controller>
          </IconList>
        </WebDevIcons>
      </Col>
    </Row>
  </>
)

export const WebDevTitle = styled.h4`
  font-size: ${rem('60px')};
  /* padding: ${rem('60px')} 0 0 0; */
  margin: 0 0 ${rem('30px')} 0;
  @media (min-height: 600px) {
    margin: 0 0 ${rem('45px')} 0;
  }
  position: relative;
  font-style: italic;
  .alt {
    font-family: ${AltFont};
    font-style: italic;
  }
  .small {
    display: block;
    font-size: ${rem('40px')};
    font-style: normal;
  }
  @media (min-width: 576px) {
    font-size: ${rem('80px')};
    .small {
      font-size: ${rem('50px')};
    }
  }
  @media (min-width: 768px) {
    padding: ${rem('75px')} 0 0 0;
  }
  @media (min-width: 992px) {
    padding: ${rem('120px')} 0 0 0;
  }
  @media (min-width: 1400px) {
    font-size: ${rem('95px')};
    .small {
      font-size: ${rem('60px')};
    }
  }
`

export const WebDevText = styled.p`
  font-size: ${rem('17px')};
  margin: 0 0 ${rem('30px')} 0;
  @media (min-height: 768px) {
    margin: 0 0 ${rem('45px')} 0;
  }
  @media (min-width: 576px) {
    font-size: ${rem('19px')};
  }
  @media (min-width: 1600px) {
    font-size: ${rem('22px')};
  }
`

const WebDevIcons = styled.div`
  text-align: center;
  margin: ${rem('60px')} 0; 
  display: none;
  @media (min-height: 768px) {
    display: inline-block;
    width: 100%;
  } 
  @media (min-width: 1200px) {
    margin: 0;
  }  
`

const IconList = styled.div`
  display: inline-block;    
  opacity: 0.6;
`

export const Icon = styled.div`
  float: left;
  margin-right: ${rem('30px')};
  height: 60px;
  width: 60px;
  &:last-child {
    margin: 0;
  }
  @media (min-width: 576px) {
    height: 90px;
    width: 90px;
  }
  @media (min-width: 1400px) {
    height: 120px;
    width: 120px;
  }
`
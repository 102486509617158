import React from 'react'
import styled from 'styled-components'
import { rem, rgba } from 'polished'
import { Controller, Scene } from 'react-scrollmagic'
import { Tween } from 'react-gsap'

import { Container, Row, Col } from '../../Container'
import { ButtonLink } from '../../Button'

import { AltFont } from '../../../utils/variables'

class AutomationFooter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isTablet: typeof window !== 'undefined' && window.innerWidth < 1199,
    };
  }
  render() {
    const data = this.props.data
    const { isTablet } = this.state
    const lineOffset = isTablet ? 1200 : 2000
    return (
      <SectionFooter>
        <Container>
          <Row justify="center">
            <Col xl={{ width: '80%' }} xxl={{ width: '70%' }} w={{ width: '60%' }}>
              <FooterContent>
                <Controller>
                  <Scene
                    duration={200}
                    offset={0}
                    reverse={false}
                    // indicators={true}
                  >
                    <Tween
                      staggerFrom={{
                        opacity: 0,
                        cycle: {
                          rotationX: [-90, 90],
                        }
                      }}
                      stagger={0.1}
                    >
                      <h3 dangerouslySetInnerHTML={{ __html: data.title }} />
                      <p dangerouslySetInnerHTML={{ __html: data.text }} />
                      <p><ButtonLink to={data.button_link} primary>{data.button_text}</ButtonLink></p>
                    </Tween>
                  </Scene>
                  <Scene
                    duration={100}
                    offset={lineOffset}
                    // pin
                    reverse={false}
                    triggerElement={null}
                    // indicators={true}
                  >
                    {(progress) => (
                      <Tween
                        from={{
                          height: '0'
                        }}
                        to={{
                          height: '120px'
                        }}
                        totalProgress={progress}
                        paused
                      >
                        <VerticalLine />
                      </Tween>
                    )}
                  </Scene>
                </Controller>
              </FooterContent>
            </Col>
          </Row>
        </Container>
      </SectionFooter>
    )
  }
}  

export default AutomationFooter

const SectionFooter = styled.div`
  display: none;
  @media (min-height: 768px) {
    display: inline-block;
    width: 100%;
  }
`

export const FooterContent = styled.div`
  text-align: center;
  h3 {
    font-size: ${rem('40px')};
    line-height: 1.3;
    span {
      font-family: ${AltFont};
      font-style: italic;
    }
  }
  p {
    font-size: ${rem('18px')};
    margin: 0 0 ${rem('60px')} 0;
    &:last-child {
      margin: 0;
    }
  }
  @media (min-width: 992px) {
    h3 {
      font-size: ${rem('50px')};
    }
    p {
      font-size: ${rem('20px')};
    }
  }
  @media (min-width: 1600px) {
    h3 {
      font-size: ${rem('55px')};
    }
    p {
      font-size: ${rem('22px')};
    }
  }
`

const VerticalLine = styled.div`
  width: 0px;
  border-right: 2px dashed ${rgba('#fff',0.2)};
  position: absolute;
  left: 50%;
  transform: translate(-50%,0);
  margin-top: -10px;
`
import React, { Component } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Slider from "react-slick"
// import Img from 'gatsby-image'
import styled from 'styled-components'
import { position, rgba, cover, rem } from 'polished'
import SVG from 'react-inlinesvg'
import Tilt from 'react-tilt'

import Container from '../Container'
import { ButtonLink } from '../Button'

import Style from './style'
import { AltFont, Semi, PDBold } from '../../utils/variables';

import Arrow from '../../images/banner-arrow-down.svg'

const HomeSlider = () => (
  <StaticQuery
    query={graphql`
      query SliderQuery {
        wordpressAcfOptions {
          options {
            video {
              localFile {
                publicURL
              }
            }
            homepage_slider {
              image {
                localFile {
                  publicURL
                  childImageSharp {
                    fluid(maxWidth: 1920, maxHeight: 1000) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                } 
              }
              slider_content {
                title
                button_text
                button_link
              }
            }
            navigation_options {
              enable_dots
              enable_arrows
              slide_display_time
            }
            styling_options {
              enable_image_overlay
              slider_animation
            }
          }
        } 
      }
    `}
    render={data =>
      <>
        <Style />
        <SliderLayout data={data} />
      </>
    }
  />
)

export default HomeSlider

class SliderLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile: typeof window !== 'undefined' && window.innerWidth < 768
    };
  }
  render() {
    const { isMobile } = this.state
    const data = this.props.data
    const options = data.wordpressAcfOptions.options
    const slider = data.wordpressAcfOptions.options.homepage_slider
    const video = data.wordpressAcfOptions.options.video.localFile.publicURL
    var settings = {
      dots: options.navigation_options.enable_dots ? true : false,
      arrows: options.navigation_options.enable_arrows ? true : false,
      vertical: options.styling_options.slider_animation === 'vertical' ? true : false,
      fade: options.styling_options.slider_animation === 'fade' ? true : false,
      infinite: true,
      autoplay: options.navigation_options.slide_display_time !== 'disabled' ? true : false,
      autoplayspeed: options.navigation_options.slide_display_time !== 'disabled' && parseInt(options.navigation_options.slide_display_time),
      slidesToShow: 1,
      slidesToScroll: 1
    }
    return (      
      <Slider { ...settings }>
        {slider.map((item,index) =>
          <Slide key={index}>
            {options.styling_options.enable_image_overlay &&
              <Overlay />
            }
            <SliderVideo width="1920px" height="1080px" autoPlay="autoplay" loop="loop" muted>
              <source src={video} type="video/mp4" />
              Your browser does not support the video tag.
            </SliderVideo>
            <img src={item.image.localFile.publicURL} alt={item.slider_content.title} className="videoPlaceholder" />
            <Content>
              <Container>
                <Tilt className="tilt" options={{ max: 15, scale: 1.01 }} >
                  <h1 dangerouslySetInnerHTML={{ __html: item.slider_content.title }} />
                  {/* <p dangerouslySetInnerHTML={{ __html: item.slider_content.text }} /> */}
                  <ButtonLink to={item.slider_content.button_link} primary>
                    {item.slider_content.button_text}
                  </ButtonLink>
                </Tilt>
              </Container>
            </Content>  
            <a href={ isMobile ? "#homepageContent" : undefined }>
              <SVG src={Arrow} />
            </a>             
          </Slide>
        )}            
      </Slider>
    )
  }
}

const Slide = styled.div`
  overflow: hidden;
`

const Content = styled.div`
  ${position('absolute','50%','','','50%')};
  transform: translate(-50%,-50%);
  width: 100%;
  z-index: 300;
  text-align: center;
  /* display: none; */
  * {
    color: #fff;
  }
  h1 {
    font-size: ${rem('70px')};
    font-weight: ${Semi};
    letter-spacing: -0.05em;
    max-width: 80%;
    margin: ${rem('45px')} auto;
    transform: translateZ(20px);
    backface-visibility: hidden;
    -webkit-font-smoothing: subpixel-antialiased;
    /* text-shadow: 2px 2px 4px ${rgba('#000',0.2)}; */
    span {
      font-family: ${AltFont};
      font-size: ${rem('40px')};
      font-weight: ${PDBold};
      font-style: italic;
      display: block;
      margin: 0 0 ${rem('10px')};
      transform: translateZ(-40px);
    }
  }
  a {
    display: none;
  }
  @media (min-height: 700px) {
    a {
      display: inline-block;
    }
  }
  @media (min-width: 768px) {
    display: block;
    h1 {
      font-size: ${rem('60px')}; 
      max-width: 50%;     
      span {
        font-size: ${rem('30px')};
      }
    }
  }
  @media (min-width: 992px) {
    h1 {
      font-size: ${rem('70px')}; 
      max-width: 40%;
      margin: 0 auto ${rem('45px')};
      span {
        font-size: ${rem('40px')};
      }
    }    
  }
  @media (min-width: 1200px) {
    h1 {
      font-size: ${rem('80px')};
      max-width: 30%;
      margin: 0 auto ${rem('60px')};
      span {
        font-size: ${rem('50px')};
        margin: 0 0 ${rem('30px')};
      }
    }
  }
  @media (min-width: 1600px) {
    h1 {
      font-size: ${rem('100px')};
      max-width: 40%;
      span {
        font-size: ${rem('60px')};
      }
    }
  }
`

const Overlay = styled.div`
  ${cover()}
  background-color: ${rgba('#000000',0.4)};
  z-index: 2;
`

const SliderVideo = styled.video`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  z-index: 1;
  height: 100%;
  width: 100%;
  object-fit: cover;
`